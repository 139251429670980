<template>
  <v-container fluid>
    <v-data-table
      :headers="displayedHeader"
      :items="form.documentSupports"
      item-key="documentInformation.id"
      hide-default-footer
      :items-per-page="100"
    >
      <template v-slot:[`item.no`]="{index}">
        <span>
          {{index + 1}}
        </span>
      </template>
      <template v-slot:[`item.documentName`]="{item, index}">
        <a
          v-if="item.url"
          @click="openDocument(item, index)"
        >
          {{item.documentInformation.descriptions}}
          <viewer-image
            :ref="'viewer_image'+index"
            :key="(item.size + index) * Math.random()"
            :src="item.url"
            class="d-none"
          />
        </a>
        <span v-else>
          {{item.documentInformation.descriptions}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.isMandatory`]="{item}">
        <span v-if="item.documentInformation.isMandatory" class="font-weight-bold">
          {{$_strings.common.YES}}
        </span>
        <span v-else>
          {{$_strings.common.NO}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.descriptions`]="{item}">
        <span v-if="item.url">
          1 dari 1 File
        </span>
        <span v-else>
          0 dari 1 File
        </span>
      </template>
      <template v-slot:[`item.actionColumn`]="{item}">
        <div>
          <v-btn
            v-if="item.url"
            color="primary"
            icon
            :loading="item.isLoading"
            :disabled="item.isLoading"
            @click="() => deleteFile(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <div v-else>
            <v-progress-circular
              v-if="item.progressUpload"
              :value="item.progressUpload"
              color="blue-grey"
            ></v-progress-circular>
            <label
              v-else
              for="file-upload"
              class="custom-file-upload"
            >
              <v-btn
                :for="'file-upload'+item.documentInformation.id"
                color="primary"
                icon
                :loading="item.loading ? true : false"
                @click="$refs['file-upload'+item.documentInformation.id].$refs.input.click()"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-file-input
                :ref="'file-upload'+item.documentInformation.id"
                class="file_input d-none"
                height="20"
                :clearable="false"
                v-model="fileUploadTemporary"
                @change="e => readFile(e, item)"
                type="file"
                accept=".pdf,.jpeg,.jpg,.png,.zip"
                outlined
                dense
                prepend-icon=""
              >
              </v-file-input>
            </label>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-row no-gutters v-if="!readOnly">
      <v-col>
        <div class="d-flex align-center">
          <v-checkbox v-model="isAgree"></v-checkbox>
          <div class="body-2">
            {{$_strings.welcomePage.ISAGREE}}
            <template>
              <a
                @click="dialogTermsConditions = true"
              >
                {{$_strings.welcomePage.TERMS}}
              </a>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!readOnly">
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          :disabled="!(isAgree)"
          @click="submit({isDraft: false})"
          color="primary"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-col>
      <v-col v-if="showButtonDraft" cols="auto">
        <v-btn
          :disabled="!(isAgree)"
          color="success"
          @click="submit({isDraft: true})"
        >
          {{$_strings.common.SAVE_AS_DRAFT}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="grey--text text--darken-1 caption">
        <span>Legend:</span>
        <p class="ma-0">Dokumen format jpeg, pdf, png, zip</p>
        <p class="ma-0">Size maksimal 1 MB</p>
        <p v-if="+$route.params.defWorkflowId === 15"  class="ma-0">Akta maksimal 7 MB</p>
      </v-col>
    </v-row>
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialog"
      :urlPdf="urlPdfSelected"
    />
    <dialog-terms-conditions
      :visible="dialogTermsConditions"
      @setDialog="setDialog"
    />
  </v-container>
</template>

<script>
export default {
  name: 'CompanyDocuments',
  props: {
    form: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.readOnly) this.headers.pop();
  },
  data() {
    return {
      isAgree: false,
      items: [],
      itemDelete: {},
      dialogDeleteDocument: false,
      dialogTermsConditions: false,
      dialogViewPdf: false,
      urlPdfSelected: '',
      fileUploadTemporary: null,
      headers: [
        {
          text: this.$_strings.companyDocuments.NO_TABLE_HEADER_LABEL,
          value: 'no',
          cellClass: 'w-10',
        },
        {
          text: this.$_strings.companyDocuments.NAME_TABLE_HEADER_LABEL,
          value: 'documentName',
        },
        {
          text: this.$_strings.companyDocuments.MANDATORY_TABLE_HEADER_LABEL,
          value: 'documentInformation.isMandatory',
          cellClass: 'w-20',
        },
        {
          text: this.$_strings.companyDocuments.DESCRIPTION_TABLE_HEADER_LABEL,
          value: 'documentInformation.descriptions',
          cellClass: 'w-20',
        },
        {
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          cellClass: 'w-10',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    readOnly(newVal) {
      if (!newVal && this.headers.length === 4) {
        this.headers.push({
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          cellClass: 'w-10',
          sortable: false,
        });
      }
    },
  },
  computed: {
    showButtonDraft() {
      const [,, path] = this.$route.path.split('/');
      return path === 'approval-inbox';
    },
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
  },
  methods: {
    submit({ isDraft }) {
      this.$emit('submitDocument', isDraft);
    },
    readFile(ev, item) {
      const { name, size } = ev;
      // GET DESCRIPTION FOR CHECK IS AKTA ?
      const isAkta = item.documentInformation.descriptions.toLowerCase().includes('akta');

      // const file = ev.target.files[0];
      const fileType = name.split('.').pop().toLowerCase();
      const fileSize = size;
      const fileName = name;
      // CHECK FILE TYPE ONLY JPG, JPEG, PDF, PNG
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'zip'];
      if (!fileTypeSupport.includes(fileType)) {
        return this.$dialog.notify.error('Format file tidak didukung');
      }
      const reader = new FileReader();
      reader.readAsDataURL(ev);
      reader.onload = (e) => {
        // 1024000 => 1MB
        // 7168000 => 7MB
        if (isAkta && fileSize > 7168000) {
          return this.$dialog.notify.error('Ukuran akta tidak boleh lebih dari 7MB.');
        }
        // 1024000 => 1MB
        if (!isAkta && fileSize > 1024000) {
          return this.$dialog.notify.error('Ukuran file tidak boleh lebih dari 1MB.');
        }
        const encodedFile = e.target.result;

        this.$dialog.confirm({
          text: `Anda yakin akan mengupload dokumen "${item.documentInformation.descriptions}"?`,
          title: this.$_strings.common.WARNING,
          persistent: true,
          actions: {
            false: this.$_strings.common.NO,
            true: this.$_strings.common.YES,
          },
        }).then(async (action) => {
          if (action) this.uploadDocument(item, encodedFile);
        });
      };
      reader.onloadend = (e) => {
        this.fileUploadTemporary = null;
      };
    },
    async uploadDocument(item, encodedFile) {
      const self = this;
      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          self.$set(item, 'progressUpload', percentCompleted);
        },
      };
      try {
        const form = {
          documentId: item.documentInformation.id,
          encodedFile,
        };
        const res = await this.$_services.registration.uploadDocumentLegal(form, config);
        this.$set(item, 'documentId', res.data.documentId);
        this.$set(item, 'documentName', res.data.documentName);
        this.$set(item, 'url', res.data.url);
        this.$set(item, 'size', res.data.size);
        this.$set(item, 'type', res.data.type);
        this.$set(item, 'noDisplay', res.data.noDisplay);
        this.$dialog.notify.success(`Berhasil mengupload dokumen ${item.documentInformation.descriptions}`);
      } finally {
        this.$delete(item, 'progressUpload');
      }
    },
    deleteFile(item) {
      this.$dialog.warning({
        text: `Anda yakin akan menghapus dokumen "${item.documentInformation.descriptions}"?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (action) => {
        if (!action) return;
        const { id: workflowId } = this.$route.params;
        try {
          this.$set(item, 'isLoading', true);
          await this.$_services.registration.deleteDocumentLegal(item.documentId, workflowId);
          this.$set(item, 'url', null);
          this.$dialog.notify.success(`Berhasil menghapus dokumen "${item.documentInformation.descriptions}"`);
        } finally {
          this.$delete(item, 'isLoading');
        }
      });
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openDocument(item, index) {
      const fileType = item.type || item.documentName.split('.').pop().toLowerCase();

      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.$refs[`viewer_image${index}`].$el.children[0].click();
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.encodedFile || item.url;
        return;
      }

      window.open(item.url);
    },
  },
};
</script>
