<template>
  <v-container fluid>
    <section v-if="isLoading">
      <v-row class="mt-1">
        <v-col cols="6">
          <v-skeleton-loader
            type="heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-skeleton-loader
              type="heading"
              class="pa-4"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="
            list-item-three-line,
            list-item-three-line,
            list-item-three-line,
            list-item-three-line,
            list-item-three-line
            "
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="isError">
      <h1 class="text-center">Terjadi Kesalahan :(</h1>
    </section>
    <section v-else>
      <v-row class="mt-1">
        <v-col>
          <span class="font-weight-bold">
            {{$route.meta.headerTitle}}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              @click="setActiveTab(0)"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyInformation.TAB_NAME}}
            </v-tab>
            <v-tab
              @click="setActiveTab(1)"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyDocuments.TAB_NAME}}
            </v-tab>
            <v-tab
              @click="setActiveTab(2)"
              class="font-weight-bold text-capitalize"
            >
              {{$_strings.companyRegistration.HISTORY_APPROVAL}}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-card
        elevation="5"
        tile
      >
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyInformation.TAB_NAME}}
            </v-card-title>
            <CompanyInformation
              :form="form"
              @selectTab="setActiveTab"
              :readOnly="true"
              ref="companyInformation"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyDocuments.TAB_NAME}}
            </v-card-title>
            <CompanyDocuments
              :readOnly="readOnly"
              :form="form"
              @submitDocument="submitData"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card-title class="font-weight-bold">
              {{$_strings.companyRegistration.HISTORY_APPROVAL}}
            </v-card-title>
            <Approval/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </section>
  </v-container>
</template>

<script>
import CompanyInformation from '../components/DocumentSupportCompany/CompanyInformation';
import CompanyDocuments from '../components/DocumentSupportCompany/CompanyDocuments';
import Approval from '../components/DocumentSupportCompany/Approval';

export default {
  name: 'CompanyRegistrationPage',
  components: {
    CompanyInformation,
    CompanyDocuments,
    Approval,
  },
  computed: {
    fromPath() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    isInboxApprovalPage() {
      const pageApproval = ['approval-inbox'];
      return pageApproval.includes(this.fromPath);
    },
  },
  data() {
    return {
      activeTab: +this.$route.query.tab || 0,
      readOnly: true,
      workflowDetailId: null,
      workflowId: '',
      defWorkflowId: 0,
      form: {
        id: 2,
        name: '',
        abbreviation: '',
        address: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        billingAddress: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        npwpNo: '',
        npwpAddress: '',
        pkpNo: '',
        presidentDirectorSalutation: '',
        presidentDirector: '',
        jobTitle: '',
        siupNo: '',
        tdpNo: '',
        email: '',
        phoneNo: '',
        faxNo: '',
        companyService: '',
        establishDate: '',
        isDraft: false,
        notes: '',
        documentSupports: [],
      },
      isRevise: '',
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    setActiveTab(tab) {
      const _tab = tab || 0;
      this.activeTab = _tab;
      if (+this.$route.query.tab !== _tab) {
        this.$router.replace({
          query: {
            tab: _tab,
          },
        });
      }
    },
    setData(data) {
      this.form = data.data;
      this.isRevise = data.status.toLowerCase() === 'revise';
      if (this.isRevise && this.isInboxApprovalPage) this.readOnly = false;
      this.workflowDetailId = data.workflowDetailId;
    },
    async fetchWorkflow() {
      try {
        this.isLoading = true;
        const { id } = this.$route.params;
        const result = await this.$_services.approval.getDocumentSupport(id);
        this.setData(result.data);
        this.isLoading = false;
      } catch (err) {
        this.isError = true;
        this.isLoading = false;
      }
    },
    validationDucumentSupport(isDraft) {
      if (isDraft) return true;
      let valid = true;
      if (this.form.documentSupports.length > 0) {
        this.form.documentSupports.forEach((document) => {
          if (!document.url && document.documentInformation.isMandatory) {
            valid = false;
          }
        });
      }
      if (!valid) {
        this.$dialog.notify.error('Mohon upload file yang wajib dilengkapi!!');
      }
      return valid;
    },
    async submitData(isDraft = false) {
      const { id } = this.$route.params;
      const validateDocument = this.validationDucumentSupport(isDraft);
      if (!validateDocument) return;
      this.$root.$loading.show();
      try {
        const workflowId = id;
        const { workflowDetailId } = this;
        this.form.isDraft = isDraft;
        await this.$_services.registration.reviseDocumentSupport(this.form, workflowId, workflowDetailId);
        if (isDraft) {
          this.$dialog.notify.success('Berhasil menyimpan draft');
        } else {
          this.$dialog.notify.success('Berhasil mengirim data');
        }
        return this.$router.replace('/main/approval-inbox');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
  mounted() {
    const { tab } = this.$route.query;
    this.setActiveTab(+tab);
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
    } else {
      this.fetchWorkflow();
    }
  },
};
</script>

<style>
</style>
